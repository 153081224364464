import React from 'react'
// import { FormattedMessage } from 'react-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import * as styles from '../../styles/Apps.module.scss'

//Components
import Layout from '../../components/layout'

const Android = ({ data, pageContext: { locale } }) => {
  const { allDatoCmsApp, allFile } = data
  return (
    <Layout locale={locale}>
      {allDatoCmsApp.edges.map(app => {
        return (
          <section key={app.node.id}>
            <div className={`${styles.appHeader} ${styles.phones}`}>
              <div className={styles.appContainer}>
                <h1 className={styles.showMobile}>
                  {app.node.appDownloadTitle}
                </h1>
                <div className={`${styles.item} ${styles.headerImg}`}>
                  <GatsbyImage
                    alt=""
                    image={app.node.appImage.gatsbyImageData}
                  />
                </div>
                <div className={styles.item}>
                  <h1 className={styles.hideMobile}>
                    {app.node.appDownloadTitle}
                  </h1>
                  <a href={app.node.appAndroid}>
                    <div className={styles.storeBtn}>
                      {allFile.edges.map(file => {
                        return (
                          <GatsbyImage
                            alt=""
                            image={file.node.childImageSharp.gatsbyImageData}
                          />
                        )
                      })}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
        )
      })}
    </Layout>
  )
}

export default Android

export const query = graphql`
  query androidQuery($locale: String!) {
    allDatoCmsApp(
      filter: { locale: { eq: $locale }, path: { eq: "android" } }
    ) {
      edges {
        node {
          id
          locale
          appTitle
          linkApp
          appDownloadTitle
          appIphone
          appAndroid
          operator
          desktopUc
          appMobile
          path
          linkOperator
          linkDesktopUc
          appImage {
            url
            gatsbyImageData(width: 600, placeholder: BLURRED)
          }
        }
      }
    }
    allFile(filter: { name: { eq: "googlestore" } }) {
      edges {
        node {
          id
          sourceInstanceName
          relativePath
          name
          childImageSharp {
            gatsbyImageData(width: 189, height: 56, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
